import './style.scss'

import $ from 'jquery'
import 'popper.js'
import 'bootstrap'

import '@iconfu/svg-inject'
import 'slick-carousel'
import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox.js'
import { Datepicker } from 'vanillajs-datepicker'
import it from '../node_modules/vanillajs-datepicker/js/i18n/locales/it.js'
Object.assign(Datepicker.locales, it)


// JQUERY DEP
$(document).ready( function() {

  var ap = true


  // IMG TO SVG
  SVGInject(document.querySelectorAll("img.svg"))


  // FANCYBOX REQ BOOK FORM
  Fancybox.bind("#nht-main-menu > li.book-now > a", {
    // Your options go here
  })
  Fancybox.bind("#booking-form-wrapper-compact .confirm-pre-submit", {
    // Your options go here
  })
  $("#booking-form-wrapper-compact .confirm-pre-submit").on("click",function(){
    var wrapper_compact = $(this).parent()
    var cin = wrapper_compact.find(".checkin input").val()
    var cout = wrapper_compact.find(".checkout input").val()
    var childs = wrapper_compact.find(".bambini input").val()
    var adu = wrapper_compact.find(".adulti input").val()

    var wrapper = $("#booking-form-wrapper")
    wrapper.find(".checkin input").val(cin)
    wrapper.find(".checkout input").val(cout)
    wrapper.find(".bambini input").val(childs)
    wrapper.find(".adulti input").val(adu)
  })


  // MAIN SLIDER HOME
  var mainS = $('.main-slider .slider-wrapper')
  mainS.on('init', function(event, slick){
    $(this).find('*[data-slick-index="0"]').find(".wrapper").addClass("ani")
  })
  mainS.on('afterChange', function(event, slick, currentSlide){
    // $(this).find(".wrapper").removeClass("ani")
    $(this).find('*[data-slick-index="'+currentSlide+'"]').find(".wrapper").addClass("ani")
    mainS.parent().find('.c-slide').text(currentSlide+1)
  })
  mainS.on('beforeChange', function(event, slick, currentSlide){
    $(this).find('*[data-slick-index="'+currentSlide+'"]').find(".wrapper").removeClass("ani")
  })
  mainS.slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 600,
    focusOnSelect: true,
    fade: false,
    infinite: true,
    arrows: true,
    variableWidth: false,
    centerMode: false,
    prevArrow: mainS.parent().find('.go-left'),
    nextArrow: mainS.parent().find('.go-right')
  })


  // CAROUSEL
  var carousel = $('.carousel .carousel-wrapper')
  carousel.slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 600,
    focusOnSelect: true,
    fade: false,
    infinite: true,
    slidesToShow: 3,
    arrows: true,
    variableWidth: false,
    dots: true,
    centerMode: true,
    prevArrow: carousel.parent().find('.go-left'),
    nextArrow: carousel.parent().find('.go-right')
  })


  // REVIEW SLIDER
  var r_slider = $('.review-slider .slider-wrapper')
  r_slider.slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 600,
    focusOnSelect: true,
    fade: true,
    infinite: true,
    arrows: true,
    variableWidth: false,
    centerMode: false,
    prevArrow: r_slider.parent().find('.go-left'),
    nextArrow: r_slider.parent().find('.go-right')
  })


  // DATE PICKER CHECK IN CHECK OUT COMPACT
  if ( (document.querySelector('#booking-form-wrapper-compact input[name="checkin"]')) && (document.querySelector('#booking-form-wrapper-compact input[name="checkout"]')) ) {

    const checkin = document.querySelector('#booking-form-wrapper-compact input[name="checkin"]')
    const checkout = document.querySelector('#booking-form-wrapper-compact input[name="checkout"]')

    checkin.setAttribute("readonly","readonly")
    checkout.setAttribute("readonly","readonly")


    var today = new Date()
    var tomorrow = new Date(today)
    var maxDate = new Date(tomorrow)

    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    today = dd + '/' + mm + '/' + yyyy

    tomorrow.setDate(tomorrow.getDate() + 1)
    dd = String(tomorrow.getDate()).padStart(2, '0')
    mm = String(tomorrow.getMonth() + 1).padStart(2, '0') //January is 0!
    yyyy = tomorrow.getFullYear()
    tomorrow = dd + '/' + mm + '/' + yyyy
    //
    // maxDate.setDate(maxDate.getDate() + 30)
    // dd = String(maxDate.getDate()).padStart(2, '0')
    // mm = String(maxDate.getMonth() + 1).padStart(2, '0') //January is 0!
    // yyyy = maxDate.getFullYear()
    // maxDate = dd + '/' + mm + '/' + yyyy


    const datepickerCheckIn = new Datepicker(checkin, {
      language: "it",
      orientation: "bottom",
      minDate: today
    })

    const datepickerCheckOut = new Datepicker(checkout, {
      language: "it",
      orientation: "bottom",
      minDate: tomorrow
    })

  }


  // DATE PICKER CHECK IN CHECK OUT FULL FORM
  if ( (document.querySelector('#booking-form-wrapper input[name="checkin"]')) && (document.querySelector('#booking-form-wrapper input[name="checkout"]')) ) {

    const checkin = document.querySelector('#booking-form-wrapper input[name="checkin"]')
    const checkout = document.querySelector('#booking-form-wrapper input[name="checkout"]')

    checkin.setAttribute("readonly","readonly")
    checkout.setAttribute("readonly","readonly")


    var today = new Date()
    var tomorrow = new Date(today)
    var maxDate = new Date(tomorrow)

    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    today = dd + '/' + mm + '/' + yyyy

    tomorrow.setDate(tomorrow.getDate() + 1)
    dd = String(tomorrow.getDate()).padStart(2, '0')
    mm = String(tomorrow.getMonth() + 1).padStart(2, '0') //January is 0!
    yyyy = tomorrow.getFullYear()
    tomorrow = dd + '/' + mm + '/' + yyyy
    //
    // maxDate.setDate(maxDate.getDate() + 30)
    // dd = String(maxDate.getDate()).padStart(2, '0')
    // mm = String(maxDate.getMonth() + 1).padStart(2, '0') //January is 0!
    // yyyy = maxDate.getFullYear()
    // maxDate = dd + '/' + mm + '/' + yyyy


    const datepickerCheckIn = new Datepicker(checkin, {
      language: "it",
      orientation: "bottom",
      minDate: today
    })

    const datepickerCheckOut = new Datepicker(checkout, {
      language: "it",
      orientation: "bottom",
      minDate: tomorrow
    })

  }


})











// import Router from './util/Router';
// import common from './routes/common';
// import home from './routes/home';
//
// /**
//  * Populate Router instance with DOM routes
//  * @type {Router} routes - An instance of our router
//  */
// const routes = new Router({
//   /** All pages */
//   common,
//   /** Home page */
//   home,
//   /** About Us page, note the change from about-us to aboutUs. */
// });
//
// /** Load Events */
// jQuery(document).ready(() => routes.loadEvents());
